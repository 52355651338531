import styled from "@emotion/styled";
import { Visibility } from "../../types";
import { breakpoints, colors, dimensions, fonts } from "../../styles/variables";
import backgroundTop from "../../assets/backgrounds/biscuit-top.svg";
import scribble from '../../assets/scribbles/button.svg';
//import backgroundBottom from "../../assets/backgrounds/biscuit-bottom.svg";
//import backgroundDiscountSection from "../../assets/deal/variation-3-box.inline.svg";
//import specialOfferIcon from '../../assets/deal/variation-3-special-offer-deal.inline.svg';
import backgroundBottom from "../../static/salespage/1st section/bottom-white-path-coverup.svg";


import illustration from "../../assets/doodles/deal-intro.svg";
import { Link } from "gatsby";


const TOP_BACKGROUND_HEIGHT = "1.45vw";
const BOTTOM_BACKGROUND_HEIGHT = "1.88vw";

interface AIPowerProps {
  showAI: boolean;
}

export const SafariWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  margin-top: -30px;
  background: url('/salespage/1st section/top-section-background.svg') no-repeat center 0;
  background-size: cover;

`;

export const Wrapper = styled.section<Visibility>`

  padding-bottom: ${BOTTOM_BACKGROUND_HEIGHT};
  margin-top: ${TOP_BACKGROUND_HEIGHT};
  margin-bottom: calc(50px + ${BOTTOM_BACKGROUND_HEIGHT});
  padding: 0 ${dimensions.containerPadding}px;
  position: relative;
  z-index: 2;
  align-items: center;
  text-align: center;
  justify-content: center;
  // background-color: ${colors.biscuit};

  /*
  opacity: 0;
  transform: translateY(10%);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  ${({ isVisible }) =>
    isVisible &&
    `
      opacity: 1;
      transform: translateY(0);
  `}*/


  /*background: url('/background-elements.png') no-repeat center 0;*/
  background-size: auto;
  background-position: 50% 50%;



  &:after {
    content: "";
    width: 100%;
    position: absolute;
    left: 0;
    background-size: contain;
  }

  &:after {
    height: 100px;
    bottom: -130px;
    background: url('/salespage/1st section/bottom-white-path-coverup.svg') no-repeat center 0;
    background-size: 100% 100px;

    @media screen and (min-width: 2300px) {
      bottom: -160px;
    }
    
    @media screen and (max-width: ${breakpoints.lg}px) {
      transform: scale(1.1);
    }
  }
  @media screen and (max-width: ${breakpoints.xl}px) {

  height: auto;
  margin-bottom: calc(60px + 1.88vw);
  }
  @media screen and (max-width: ${breakpoints.lg}px) {
    height: auto;
    margin-bottom: 80px;
  }
  
`;

export const Section = styled.section`

  max-width: 1110px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  box-sizing: border-box;
  grid-column-gap: 32px;

  @media screen and (min-width: 1024px) and (max-width: ${breakpoints.lg}px) {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  @media screen and (max-width: ${breakpoints.lg}px) {
    padding-top: 20px;
    padding-bottom: 40px;
  }

  @media screen and (max-width: ${breakpoints.sm}px) {
    padding-bottom: 0px;
  }

  &:after {
    content: "";
    width: 1513px;
    height: 675px;
    position: absolute;
    top: -43px;
    z-index: 2;

    transform: translate(2px, -79px);
    justify-self: center;
  }
`;

export const Content = styled.div`
  padding-bottom: 6px;
  position: relative;
  z-index: 4;

  @media screen and (max-width: ${breakpoints.lg}px) {

    margin-top: 20px;

    align-items: center;
  }

  @media screen and (max-width: ${breakpoints.md}px) {
    max-width: 100%;
  }
`;

export const Heading = styled.header`
  width: 96%;
  margin: auto;
  margin-bottom: 24px;
  @media screen and (max-width: ${breakpoints.sm}px) {
    width: 100%;
  }
`;

export const Title = styled.h2<AIPowerProps>`
  margin: 0;
  color: ${colors.darkNavyBlue};
  font-size: ${dimensions.fontSize.headingMajor}px;
  font-weight: 900;
  line-height: ${dimensions.lineHeight.headingMajor};


  @media screen and (max-width: ${breakpoints.lg}px) {
    font-size: ${dimensions.fontSize.heading * 0.7}px;
  }

  @media screen and (max-width: ${breakpoints.md}px) {
    text-align: center;
  }


  ${({ showAI }) =>
  showAI &&
    `
      &:before {
        content: "";
        height: 45px;
        width: 150px;
        background: url('/salespage/1st section/ai-powered-text.svg') no-repeat center 0;
        background-size: 100% 45px;

        margin-left: 208px;
        margin-top: -30px;
        position: absolute;
        
        @media screen and (max-width: ${breakpoints.lg}px) {
          background-size: 100% 35px;
          margin-left: 100px;
        }
      
       

        @media screen and (max-width: ${breakpoints.md}px) {
          background-size: 100% 25px;
        }

        @media screen and (max-width: ${breakpoints.sm}px) {
          background-size: 100% 30px;
          margin-left: 100px;
          margin-top: -20px;
        }
      }
    `
  }
`;

export const TitleWords = styled.h2`
  margin: 0;
  color: ${colors.darkNavyBlue};
  font-size: ${dimensions.fontSize.headingMajor}px;
  font-weight: 900;
  line-height: ${dimensions.lineHeight.headingMajor};
  display: inline;

  @media screen and (max-width: ${breakpoints.lg}px) {
    font-size: ${dimensions.fontSize.heading * 0.7}px;
  }
`;

export const ArrowLeft = styled.div`
&:before {
    content: url("/salespage/1st section/red-arrow-left.svg"); 
    @media screen and (max-width: ${breakpoints.xl}px) {
      content:'';
      background-image:url("/salespage/1st section/red-arrow-left.svg"); 
      background-repeat:no-repeat;width:90px;height:145px;display:block;
      background-size:80%;
    }
    @media screen and (max-width: ${breakpoints.lg}px) {
      background-size:55%;
    }
    @media screen and (max-width: ${breakpoints.md}px) {
      width:50px;height:55px;background-size:60%;
    }
  }

  
  float: left;
  height: 138px;
  display: absolute;
  margin-top: 40px;
  @media screen and (max-width: ${breakpoints.lg}px) {
    margin-top: 0px;height:inherit;margin-left:-20px;
  }

  @media screen and (max-width: ${breakpoints.md}px) {
    height:50px;
    margin-top:20px;

  }

`;

export const ArrowRight = styled.div`
&:after {
    content: url("/salespage/1st section/red-arrow-right.svg"); 
    @media screen and (max-width: ${breakpoints.xl}px) {
      content:'';
      background-image:url("/salespage/1st section/red-arrow-right.svg"); 
      background-repeat:no-repeat;width:90px;height:145px;display:block;
      background-size:80%;
    }
    @media screen and (max-width: ${breakpoints.lg}px) {
      background-size:55%;
    }
    @media screen and (max-width: ${breakpoints.md}px) {
      width:50px;height:55px;background-size:60%;
    }
  }

  float:right;
  height: 138px;
  display: absolute;
  margin-top: 40px;
  @media screen and (max-width: ${breakpoints.lg}px) {
    margin-top: 0px;height:inherit;margin-right:-30px;

  }
  @media screen and (max-width: ${breakpoints.md}px) {
    margin-top:20px;

  }

`;

export const SubTitle = styled.h3`
  margin: 16px 0 0 0;
  color: ${colors.darkNavyBlue};
  font-family: ${fonts.default};
  font-size: ${dimensions.fontSize.large}px;
  line-height: ${dimensions.lineHeight.large};
  font-weight: 400;
  margin-left: -20px;

  @media screen and (max-width: 912px) {
    text-align: center;
  }

  @media screen and (max-width: ${breakpoints.md}px) {
    text-align: center;
  }
  @media screen and (max-width: ${breakpoints.lg}px) {
    font-size: ${dimensions.fontSize.large * 0.7}px;
  }

  @media screen and (max-width: 1024px) {
    font-size: ${dimensions.fontSize.large * 0.7}px;
  }
  @media screen and (max-width: 360px) {
    font-size: ${dimensions.fontSize.large * 0.65}px;
  }

`;

export const HeartLeft = styled.div`
  &:before {
    content: url("/salespage/1st section/3-hearts.svg");
    @media screen and (max-width: ${breakpoints.xl}px) {
      content:'';
      background-image: url("/salespage/1st section/3-hearts.svg");
      background-repeat:no-repeat;width:110px;height:240px;display:block;
      background-size:90%;
    }

    @media screen and (max-width:1024px) {
      background-size:85%;;width:100px;height:200px;
    }
    @media screen and (max-width: ${breakpoints.lg}px) {
      background-size:60%;width:80px;height:150px;
    }
    @media screen and (max-width: ${breakpoints.md}px) {
      background-size:50%;width:70px;height:85px;
    }
   
  }

  float: left;
  display: absolute;
  margin-top: 120px;
  margin-left: -110px;
  @media screen and (max-width: ${breakpoints.lg}px) {
    margin-left: 0px;position:absolute;left:-20px;  margin-top: 150px;
  }
  @media screen and (max-width: ${breakpoints.md}px) {
    margin-top: 60px;
   }
  
   @media screen and (max-width: 599px) {
      display:none;
   }
`;

export const FireRight = styled.div`
&:after {
    content: url("/salespage/1st section/3-fire.svg"); 
    @media screen and (max-width: ${breakpoints.xl}px) {
      content:'';
      background-image: url("/salespage/1st section/3-fire.svg"); 
      background-repeat:no-repeat;width:110px;height:250px;display:block;
      background-size:90%;
    }   
    @media screen and (max-width:1024px) {
      background-size:85%;;width:100px;height:230px;
    } 
    @media screen and (max-width: ${breakpoints.lg}px) {
      width:80px;height:150px;
      background-size:60%;
    }
    @media screen and (max-width: ${breakpoints.md}px) {
      width:70px;height:100px;
      background-size:50%;
    }
       
  }

  float:right;
  display: absolute;
  margin-top: 120px;
  margin-right: -100px;
  @media screen and (max-width: ${breakpoints.xl}px) {
    margin-right: -120px;

  }
  @media screen and (max-width: ${breakpoints.lg}px) {
    position:absolute;right:-50px;  margin-right: 0px ; margin-top:150px;

  }
  @media screen and (max-width: ${breakpoints.md}px) {
    margin-top: 60px;right:-40px;    
  }
  @media screen and (max-width: 599px) {
    display:none;
 }
`;

export const HandLeft = styled.div`
  &:before {
    content: url("/salespage/1st section/left-hand.svg"); 
    position: absolute;
    z-index: 2;
    @media screen and (max-width: ${breakpoints.xl}px) {
      content:'';
      background-image: url("/salespage/1st section/left-hand.svg"); 
      background-repeat:no-repeat;background-size:cover;
      width:100px;height:48px;top:30px;
    }
    @media screen and (max-width: ${breakpoints.lg}px) {
      top:0;
    }
   
  }
  float: left;
  margin-left: -30px;
  @media screen and (max-width: ${breakpoints.xl}px) {
    position:absolute;left:60px;margin-left:0;
  }
  @media screen and (min-width: 1024px) and (max-width: 1100px) {
    left:0;
  }
  @media screen and (max-width: 1023px) {
    left:-20px;
  }
 
  @media screen and (max-width:599px) {
    display:none;
  }

`;

export const HandRight = styled.div`
  &:after {
    content: url("/salespage/1st section/right-hand.svg"); 
    position: absolute;
    z-index: 2;
    @media screen and (max-width: ${breakpoints.xl}px) {
      content:'';
      background-image: url("/salespage/1st section/right-hand.svg");
      background-repeat:no-repeat;background-size:cover;
      width:100px;height:48px;right:0;top:30px;
    }
    @media screen and (max-width: ${breakpoints.lg}px) {
      top:0;
    }
  }
  float:right;
  margin-right: 170px;
  @media screen and (max-width: ${breakpoints.xl}px) {
    position:absolute;right:60px;margin-right:0;
  }
  @media screen and (min-width:1024px) and (max-width: 1100px) {
    right:0px;
  }
  @media screen and (max-width: 1023px) {
    right:-20px;
  }
  
  @media screen and (max-width:599px) {
    display:none;
  }
`;

export const Figure = styled.figure`
  margin: 0 0 0 auto;
  padding: 2px 0 0 0;
  position: relative;
  z-index: 3;
  padding: 8px;
  background: url("/SW-slide-show-border.png") no-repeat;
  background-size: contain;
  background-position: center;

  img{
    width: 800px;
  }

  iframe{
    width: 800px;
    height: 458px;
  }


  @media screen and (min-width: 1024px) and (max-width: 1024px) {
    transform: scale(0.8) !important;
    
  }

  @media screen and (min-width: 780px) and (max-width: 880px) {
    img{
      width: 700px;
    }

    iframe{
      width: 700px;
      height: 358px;
    }
  
  }

  @media screen   and (max-width: 780px) {
    img{
      width: 100%;
    }

    iframe{
      width: 630px;
      height: 360px;
    }
  }

  @media screen   and (max-width: 540px) {

    iframe{
      width: 460px;
      height: 280px;
    }
  }

  @media screen   and (max-width: 420px) {
    iframe{
      width: 340px;
      height: 200px;
    }
  }

  @media screen   and (max-width: 400px) {
    iframe{
      width: 320px;
      height: 200px;
    }
  }

  @media screen   and (max-width: 380px) {
    iframe{
      width: 300px;
      height: 180px;
    }
  }
`;


export const DiscountSection = styled.div`
  display: grid;

  grid-template-columns: 98px 1fr 187px;
  grid-column-gap: 16px;
  align-items: center;
  justify-content: flex-start;

  @media screen and (max-width: ${breakpoints.md}px) {
    display: flex;
    margin-left: 0;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }
`;

export const PriceSection = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${breakpoints.md}px) {
    margin: 20px 0;
  }


`;



export const OldPrice = styled.del`
  color: ${colors.darkNavyBlue};
  font-size: ${dimensions.fontSize.regular}px;
  line-height: ${dimensions.lineHeight.regular};
`;

export const CurrentPrice = styled.strong`
  display: block;
  margin-top: -4px;
  color: ${colors.darkNavyBlue};
  font-size: ${dimensions.fontSize.large}px;
  line-height: ${dimensions.lineHeight.large};
  font-weight: 900;
`;

export const PriceInfo = styled.small`
  margin-top: 7px;
  color: ${colors.lightGray};
  font-weight: 600;
  font-size: 12px;
  line-height: ${dimensions.lineHeight.heading};
`;

export const ButtonSection = styled.div`
  display: flex;
  padding-top: 2px;
  flex-direction: column;
  align-items: center;
  transform: translateX(-12px);
`;



export const Button = styled.a`
  display: inline-flex;
  width: 80%;
  height: 56px;
  margin-right: auto;
  padding: 0;
  border: 0;
  border-radius: 28px;
  position: relative;
  background: #BFF672;
  align-items: center;
  justify-content: center;
  color: #000;
  font-weight: 600;
  cursor: pointer;
  text-transform: uppercase;
  text-decoration: none;
  transition: background-color 0.1s ease-in-out;
  margin-top: 20px;
  &:hover,
  &:focus,
  &:active {
    background-color: #BFF672;
    text-decoration: none;
  }

  &:before,
  &:after {
    content: '';
    width: 24px;
    height: 33px;
    position: absolute;
    top: 12px;
    background: url('/salespage/1st section/btn-3stroke-line-left.svg') no-repeat 0 0;
    background-size: contain;
  }

  &:before {
    left: -32px;
  }
  &:after {
    right: -32px;
    transform: scale(-1, 1);
  }
  @media screen and (max-width: ${breakpoints.md}px) {
    padding: 0 12px;
  }

  @media screen and (max-width: 540px) {
    position: relative;
    z-index: 500;
    margin-top: -10px;
  }
`;

export const ButtonHome = styled(Link)`
  display: inline-flex;
  height: 56px;
  margin-top: 6px;
  padding: 0 32px;
  border-radius: 28px;
  position: relative;
  background-color: ${colors.blue};
  align-items: center;
  font-size: ${dimensions.fontSize.regular};
  font-weight: 600;
  color: ${colors.white};
  text-transform: uppercase;
  text-decoration: none;

  &:hover,
  &:focus,
  &:active {
    background-color: #1f62ff;
    text-decoration: none;
  }

  &:before,
  &:after {
    content: '';
    width: 24px;
    height: 33px;
    position: absolute;
    top: 12px;
    background: url(${scribble}) no-repeat 0 0;
    background-size: contain;
  }

  &:before {
    left: -32px;
  }
  &:after {
    right: -32px;
    transform: scale(-1, 1);
  }
`;


export const AppInfo = styled.div`
  margin-top: 12px;
  color: ${colors.lightGray};
  font-size: 12px;
  line-height: ${dimensions.lineHeight.heading};
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: right;
  width: 800px;
  @media screen and (max-width: ${breakpoints.lg}px) {
    width: 100%;

  }
`;

export const Badge = styled.div`
  width: 400px;
  overflow: hidden;
  background: url('/salespage/1st section/limited-time-offer.svg') no-repeat;
  background-size: contain;
  height: 100px;
  margin-left: -40px;

  @media screen and (max-width: 840px) {
    margin-left: -10px;
  }

  @media screen and (min-width: 600px) and (max-width: 700px) {
    background-size: 65%;
    width: 85%;
  }
  @media screen and (min-width: 579px) and (max-width: 599px) {
    width: 280px;
  }
  @media screen and (max-width: 540px) {
    margin: auto;
    position: relative;
    z-index: 500;
  }

  @media screen and (max-width: 420px) {
    width: 100%;
  }
`;

export const MonthSpecial = styled.div`
  font-weight: 700;
  line-height: ${dimensions.lineHeight.heading};
  /*position: absolute;*/
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: right;
  width: 800px;
  margin-bottom: -15px;

  img{
    width: 15px !important;
  }
  @media screen and (max-width: ${breakpoints.sm}px) {
    font-weight:500;
    font-size: 13px;
    margin-bottom: 15px;
    text-align: center;
  }

  @media screen and (max-width: ${breakpoints.lg}px) {
    width: 100%;
    margin-top: -15px;
  }

  @media screen and (min-width: 900px) and (max-width: 1100px) {
    width: 760px;
  }



`;

export const GreenHighlight = styled.span`
  background-color: #BFF672;
`;